*{
  margin: 0px;
  padding: 0px;
}
.login {
  margin: 62px auto;
  padding: 2.4vw;
  border-radius: 12px;
  background: rgb(135, 161, 115);
  width: fit-content;

}

.header {
  grid-area: header;
  display: flex;
  background: #6da5d5;
}

.logo {
  font-size: 1rem;
  color: antiquewhite;
  margin: 0px;
  padding: 14px;
  width: 217px;
  background: lightgray;
}

.manu {
  background: #5b5b5b;
  position: absolute;
  top: 2.9rem;
  width: 0rem;
  bottom: 0;
  overflow: hidden;
  box-shadow: 0 0 60px;
  font-size: medium;
  color: white;
  transition: all 0.3s;
  z-index: 1;
}

.manu ol {
  list-style: none;
  letter-spacing: 2px;
  text-align: left;
  padding: 0px;
  font-size: medium;
  text-transform: capitalize;
}

.manu ol li {
  padding: 12px;
  cursor: pointer;
  background: black;
}

.manu ol li a {
  text-decoration: none;
  color: white;
  /* display: none; */
}

.menu-icon {
  width: 30px;
  height: 4px;
  position: relative;
  left: 12px;
  background-color: #ad5858;
  margin: 6px 0px;
}

.mystyle {
  width: 13.6rem;
}
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
.logo1{
  text-align: center;
  color: #6da5d5;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 800;
}
.nav{
  margin-left: 40px;
}
.nav li{
  list-style: none;
  padding: 15px 15px;
}
.nav li a{
  color: #04AA6D;
  text-decoration: none;
  font-weight: 600;
}
.margin{
  margin-right: 20px;
}
